import { Modal } from "bootstrap";

export class ModalInfo {
    constructor(
        modal,
        title,
        body,
        confirmText = "Confirm",
        cancelText = "Cancel",
        hideCancel = false,
        cancelFn = undefined,
        confirmFn = undefined
    ) {
        this.modal = modal;
        this.title = title;
        this.body = body;
        this.hideCancel = hideCancel;
        this.confirmText = confirmText;
        this.cancelText = cancelText;

        this.callbacks = {
            cancelFn,
            confirmFn,
        };

        this.setup();
    }

    setup() {
        this.modal.querySelector(".modal-title").textContent = this.title;
        this.modal.querySelector(".modal-body").innerHTML = this.body;

        // confirm button
        const btnConfirm = this.modal.querySelector("#btn-save");
        btnConfirm.textContent = this.confirmText;
        if (this.callbacks["confirmFn"]) {
            btnConfirm.addEventListener("click", (event) => {
                event.preventDefault();
                this.callbacks["confirmFn"]();
            });
        }

        // cancel button
        const btnCancel = this.modal.querySelector("#btn-close");
        btnCancel.textContent = this.cancelText;
        if (this.callbacks["cancelFn"]) {
            btnCancel.addEventListener("click", (event) => {
                event.preventDefault();
                this.callbacks["cancelFn"]();
            });
        }

        if (this.hideCancel) {
            btnCancel.classList.add("d-none");
        }

        new Modal(this.modal, {
            focus: true,
            keyboard: true,
        }).show();
    }
}
